<template>
  <div>
    <navbar title="实名认证" />
    <div class="head">
      <div class="name">
        <img src="../../assets/images/people.png" />
        <div>
          <van-cell-group>
            <van-field v-model="username" placeholder="请输入用户名"    @input="nameFocus" />
          </van-cell-group>
        </div>
      </div>
      <div class="IDnumber">
        <img src="../../assets/images/identity.png" />
        <div>
          <van-cell-group>
            <van-field v-model="IDnumber" placeholder="请输入身份证号" />
          </van-cell-group>
        </div>
      </div>
    </div>

    <div>
      <div class="upload">上传人脸照片</div>
    </div>
    <div class="bottom-img">
      <van-uploader v-model="showImg" max-count="2" :deletable="false" :after-read="afterRead" :preview-full-image="false" :preview-image="false" accept="image/*">
        <template #default v-if="avatarUrl">
          <img :src="avatarUrl" alt="" />
        </template>
      </van-uploader>
    </div>
    <div class="bottom-btn">
      <van-button type="primary" @click="goIndex">取消</van-button>
      <van-button type="info" @click="goCertification">认证</van-button>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";

export default {
  name: "MyCertification",
  data() {
    return {
      username: "",
      IDnumber: "",
      showImg: [],
      avatarUrl: require("../../assets/images/upload_face.png"),
    };
  },
  methods: {
    //上传图片
    afterRead(res) {
      this.showImg = [];
      this.avatarUrl = res.content;
      this.showImg.push(res);
    },
    goIndex() {
      this.username = "";
      this.IDnumber = "";
      this.showImg = [];
      this.$router.push("/my/index");

    },
     nameFocus(value) {
      this.username = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
    },
    goCertification() {
      if (this.username == "" || this.IDnumber == "") {
        this.$toast("请输入用户名和身份证号");
      } else {
        this.$router.push("/my/Certification");
      }
    },
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.head {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  .name {
    display: flex;
    font-size: 20px;
    color: #363535;
    align-items: center;
    border:1px solid #dbd9d9;
    background-color: white;
    img {
      width: 20px;
      height: 20px;
      padding-left: 5px;
    }
  }

  .IDnumber {
    display: flex;
    font-size: 15px;
    color: #363535;
    align-items: center;
    border:1px solid #dbd9d9;
    margin-top: 20px;
    background-color: white;
    img {
      width: 20px;
      height: 20px;
      padding-left: 5px;
      
    }
  }
}
.upload {
  width: 335px;
  height: 45px;
  color: #444444;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 25px;
}
.bottom-img {
  width: 335px;
  height: 225px;
  border: 1px dotted #838181;
  margin-left: 20px;
  img {
    width: 335px;
    height: 225px;
  }
}
 /deep/.van-field__control {
      width: 275px;
    }
/deep/.van-cell {
  line-height: 9px;
}
.van-button--primary {
  background: #c9c9c9;
  border: none;
  width: 141px;
  height: 39px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 17px;
}
.van-button--info {
  background-color: #00d3c4;
  border: none;
  width: 141px;
  height: 39px;
  border-radius: 5px;
  font-size: 17px;
}
</style>