<template>
  <div class="nav-bar">
    <van-nav-bar :title="title" fixed left-arrow placeholder @click-left="onClickLeft" />
  </div>
</template>
<script>
export default {
  name: "navbar",
  props: {
    title: {
      type: String,
      default: "",
    },
    path: {
      trpe: String,
      default: "",
    },
  },
  methods: {
    onClickLeft() {
      if (this.path) {
        this.$router.push(this.path);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.nav-bar {
  position: relative;
  z-index: 100;
}
/deep/.van-nav-bar {
  border-bottom: 1px solid #ddd;
  .van-icon {
    color: #9f9f9f;
    font-size: 18px;
  }
}
</style>